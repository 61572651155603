import React, { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { analytics } from 'helpers'
import cx from 'classnames'

import Box from 'components/layout/Box/Box'
import Icon from 'components/ui/icons/Icon/Icon'
import Overlay from 'components/ui/Overlay/Overlay'

import s from './Modal.module.scss'


export type ModalProps = {
  className?: string
  analyticsName?: string
  dataTestId?: string
  closeModal: Modals.VisibilityProps['closeModal'] | null
}

const Modal: React.FC<ModalProps> = (props) => {
  const { children, className, analyticsName, dataTestId, closeModal } = props

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (analyticsName) {
      analytics.sentry.breadcrumb({ message: `opened modal: ${analyticsName}` })
      analytics.statistics.sendEvent(`Opened modal`, {
        name: analyticsName,
      })
    }
  }, [])

  const handleClose = () => {
    if (containerRef.current && closeModal) {
      if (analyticsName) {
        analytics.sentry.breadcrumb({ message: `closed modal: ${analyticsName}` })
        analytics.statistics.sendEvent(`Closed modal`, {
          name: analyticsName,
        })
      }

      containerRef.current.classList.add(s.close)
      setTimeout(closeModal, 400) // for animation
    }
  }

  const handleContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  return createPortal(
    <div ref={containerRef}>
      <Overlay className={s.overlay} onClick={handleClose}>
        <div className={s.container} onClick={handleContainerClick}>
          <Box
            className={cx(s.modal, className)}
            p={24}
            relative
            dataTestId={dataTestId}
          >
            {
              typeof closeModal === 'function' && (
                <button
                  className={s.closeButton}
                  type="button"
                  data-testid={dataTestId ? `${dataTestId}-close` : ''}
                  onClick={handleClose}
                >
                  <Icon name="fa-solid fa-xmark" size={24} color="default"/>
                </button>
              )
            }
            {children}
          </Box>
        </div>
      </Overlay>
    </div>,
    document.getElementById('modals') as HTMLElement
  )
}

Modal.displayName = 'Modal'


export default Modal
