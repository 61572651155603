import { useDevice } from 'device'
import { useRouter } from 'next/router'
import { useConfig, useActions } from 'hooks'
import { constants, commonMessages } from 'helpers'

import { openAddTokensModal } from '../../SettingsModals/AddTokensModal/AddTokensModal'
import { openSendTokensModal } from '../../SettingsModals/SendTokensModal/SendTokensModal'
import { openTransactionsModal } from '../../SettingsModals/TransactionsModal/TransactionsModal'
import { openSwitchAccountModal } from '../../SettingsModals/SwitchAccountModal/SwitchAccountModal'

import messages from './messages'


type Input = {
  buyNativeToken: (() => void) | null
}

type Output = {
  items: Array<{
    title: Intl.Message
    iconName: string
    onClick: () => void
  } | 'line'>
}

const useMenu = (values: Input) => {
  const { buyNativeToken } = values

  const router = useRouter()
  const actions = useActions()
  const { isMobile } = useDevice()
  const { activeWallet, config, isMainnet, deactivate } = useConfig()

  const isDAppBrowser = activeWallet === constants.walletNames.dAppBrowser
  const isLedgerLive = activeWallet === constants.walletNames.ledgerLive
  const isGnosisWallet = activeWallet === constants.walletNames.gnosis
  const isMetaMask = activeWallet === constants.walletNames.metaMask

  const disableDisconnectButton = (isMobile && (isDAppBrowser || isMetaMask)) || (isGnosisWallet || isLedgerLive)

  const items: Output['items'] = [
    {
      iconName: 'fa-solid fa-gears',
      title: commonMessages.settings,
      onClick: () => router.push('/settings'),
    },
    {
      iconName: 'fa-solid sack-xmark',
      title: messages.vestings,
      onClick: () => router.push('/vestings'),
    },
  ]

  if (isMetaMask) {
    items.push({
      iconName: 'fa-solid fa-plus',
      title: messages.addTokens,
      onClick: openAddTokensModal,
    })
  }

  if (isMainnet && typeof buyNativeToken === 'function') {
    const { tokens: { nativeToken } } = config

    const title = {
      ...messages.buyNativeToken,
      values: { nativeToken },
    }

    items.push({
      title,
      iconName: 'fa-regular fa-credit-card',
      onClick: buyNativeToken,
    })
  }

  items.push(
    'line',
    {
      iconName: 'fa-solid fa-list-ul',
      title: messages.transactions,
      onClick: openTransactionsModal,
    },
    {
      iconName: 'fa-solid fa-square-arrow-up-right',
      title: messages.sendTokens,
      onClick: openSendTokensModal,
    }
  )

  if (!disableDisconnectButton) {
    items.push('line')

    if (activeWallet === constants.walletNames.ledger) {
      items.push({
        iconName: 'fa-solid fa-arrows-spin',
        title: messages.switchAccount,
        onClick: openSwitchAccountModal,
      })
    }

    items.push({
      iconName: 'fa-solid fa-arrow-right-from-bracket',
      title: messages.disconnect,
      onClick: () => deactivate(),
    })
  }

  return {
    items,
  }
}


export default useMenu
