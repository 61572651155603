import React from 'react'

import { Flex, Box } from 'components/layout'

import PageButton from './PageButton/PageButton'
import usePagination from './util/usePagination'
import ArrowButton from './ArrowButton/ArrowButton'


export type PaginationProps = {
  count: number
  items: Array<any>
  containerClassName?: string
  render: (items: PaginationProps['items']) => React.ReactNode
}

const pagesInRow = 5

const getPagesArr = (currentPage: number, maxPages: number) => {
  const isSmall = pagesInRow > maxPages
  const arrLength = isSmall ? maxPages : pagesInRow

  return Array.from({ length: arrLength }, (_, index) => {
    if (isSmall) {
      return index + 1
    }

    const inCenter = currentPage > 2
    const inLasts = currentPage > (maxPages - 2)

    if (inCenter && !inLasts) {
      return index + currentPage - 2
    }

    if (inLasts) {
      const nextRest = maxPages - currentPage + 1
      const shift = pagesInRow - nextRest

      return index + currentPage - shift
    }

    return index + 1
  })
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const { items, count, containerClassName, render } = props

  const {
    maxPages,
    currentPage,
    croppedItems,
    isDisabledNext,
    isDisabledPrev,
    onNewPage,
    onNextPage,
    onPrevPage,
  } = usePagination({ items, count })

  if (maxPages === 0) {
    return (
      <div className={containerClassName}>
        {render(croppedItems)}
      </div>
    )
  }

  const pageButtons = getPagesArr(currentPage, maxPages )

  return (
    <>
      <div className={containerClassName}>
        {render(croppedItems)}
      </div>
      <Box mt={32}>
        <Flex justify="center">
          <ArrowButton
            iconName="fa-solid fa-chevron-left"
            disabled={isDisabledPrev}
            onClick={onPrevPage}
          />
          <Flex>
            {
              pageButtons.map((page) => (
                <PageButton
                  key={page}
                  page={page}
                  onClick={onNewPage}
                  isActive={page === currentPage}
                />
              ))
            }
          </Flex>
          <ArrowButton
            iconName="fa-solid fa-chevron-right"
            disabled={isDisabledNext}
            onClick={onNextPage}
          />
        </Flex>
      </Box>
    </>
  )
}


export default Pagination
