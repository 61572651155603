import React, { useRef, useEffect, useCallback } from 'react'
import { useDevice } from 'device'
import cx from 'classnames'

import { Icon, Text } from 'components/ui'
import { Box, Flex } from 'components/layout'

import s from './Notification.module.scss'


const types = {
  info: {
    className: s.info,
    iconName: 'fa-regular fa-lightbulb',
  },
  success: {
    className: s.success,
    iconName: 'fa-regular fa-circle-check',
  },
  error: {
    className: s.error,
    iconName: 'fa-solid fa-triangle-exclamation',
  },
  warning: {
    className: s.warning,
    iconName: 'fa-solid fa-triangle-exclamation',
  },
} as const

const Notification: React.FC<Notifications.NotificationProps> = (props) => {
  const { text, type, closeNotification } = props

  const { isMobile } = useDevice()
  const nodeRef = useRef<HTMLDivElement>(null)

  const handleClose = useCallback(() => {
    if (nodeRef.current) {
      nodeRef.current.style.marginTop = `${-1 * nodeRef.current.clientHeight}px`
      nodeRef.current.classList.add(s.closed)
      setTimeout(closeNotification, 300) // For animation
    }
  }, [ closeNotification ])

  useEffect(() => {
    const timer = setTimeout(handleClose, 6000)

    return () => {
      clearTimeout(timer)
    }
  }, [ handleClose ])

  const { className, iconName } = types[type] || {}

  return (
    <Box
      className={cx(s.notification, className)}
      p={isMobile ? 8 : 16}
      mt={isMobile ? 8 : 16}
      radius={8}
      ref={nodeRef}
    >
      <Flex>
        <Icon name={iconName} size={28} color="white" />
        <Box className={s.text} ph={8} alignCenter>
          <Text message={text} color="white" size={14} html />
        </Box>
        <button type="button" onClick={handleClose}>
          <Icon name="fa-solid fa-xmark" size={24} color="white"/>
        </button>
      </Flex>
    </Box>
  )
}


export default Notification
