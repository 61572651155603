import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import localStorage from 'local-storage'
import { analytics, commonMessages, constants } from 'helpers'

import { Text, Icon } from 'components/ui'
import { Box } from 'components/layout'

import messages from './messages'
import s from './AnalyticsNotify.module.scss'


const AnalyticsNotify = () => {
  const router = useRouter()
  const [ isShowNotify, toggleShowingNotify ] = useState(false)

  useEffect(() => {
    const isSettingsPage = router.pathname === '/settings'

    if (isSettingsPage) {
      return
    }

    const isPreventAnalytics = analytics.statistics.getPreventAnalyticsStatus()

    toggleShowingNotify(isPreventAnalytics === null)
  }, [])

  const handleClick = () => {
    const isPreventAnalytics = analytics.statistics.getPreventAnalyticsStatus()

    localStorage.setItem(constants.localStorageNames.isPreventAnalytics, Boolean(isPreventAnalytics))
    toggleShowingNotify(false)
  }

  if (!isShowNotify) {
    return null
  }

  return (
    <Box className={s.notify} mh={12} pv={16} ph={20} radius={8}>
      <span>
        <Text
          message={messages.text}
          color="white"
          tag="span"
          size={14}
        />
        {' '}
        <Link href="/settings" passHref>
          <a data-testid="analytics-notify-settings-link" onClick={handleClick}>
            <Text
              message={commonMessages.settings}
              size={14}
              tag="span"
            />
          </a>
        </Link>
      </span>
      <button
        className={s.closeButton}
        type="button"
        data-testid="analytics-notify-settings-close-btn"
        onClick={handleClick}
      >
        <Icon name="fa-solid fa-xmark" size={24} color="white"/>
      </button>
    </Box>
  )
}

export default React.memo(AnalyticsNotify)
