import React from 'react'
import cx from 'classnames'

import { Icon } from 'components/ui'

import s from './SwiseDropButton.module.scss'


type SwiseDropButtonType = {
  onClick: () => void
}

const SwiseDropButton: React.FC<SwiseDropButtonType> = (props) => {
  const { onClick } = props

  return (
    <button
      className={s.button}
      type="button"
      data-testid="swise drop header button"
      onClick={onClick}
    >
      <Icon name="fa-solid fa-gift" size={20} />
    </button>
  )
}


export default React.memo(SwiseDropButton)
